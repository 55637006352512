
class AppConfig {
    baseUrl: string;
    colors: {
        primary: string;
        secondary: string;
    };

    constructor() {

        var baseURL = "";
        if(process.env.REACT_APP_BASE_URL) {
            // base url available in .env
            baseURL = process.env.REACT_APP_BASE_URL;
        } else {
            // resolve from hostname
            baseURL = window.location.protocol + "//" + window.location.hostname;
            if (window.location.port) {
                baseURL += ":" + window.location.port;
            }
            // detect localhost
            if (baseURL === 'http://localhost:3000') {
                baseURL = "https://brokercrm.co.uk";
            } else {
                baseURL = baseURL.replace(/portal\./g, '');
            }
        }
        console.log(`baseURL resolved to ${baseURL}`)
        this.baseUrl = baseURL;
        this.colors = {
            primary: '',
            secondary: '',
        };
    }
}

export const appConfig = new AppConfig();