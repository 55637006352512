import moment from "moment";

export const convertUnixTimestampToDate = (DateOfBirth: string | null | undefined): string => {
    if(!DateOfBirth) {
        return "";
    }
    //   /Date(-62135596800000)/    len=23
    //   /Date(644108400000)/       len=20
    let len = DateOfBirth.length;
    let format = 'DD-MM-YYYY';
    let unix_timestamp = parseInt(DateOfBirth.replace('/Date(', '').replace(')/', ''));
    let m = moment.unix(unix_timestamp/ (len===23 ? 100000 : 1000));
    return m.format(format);
    // console.log(len + '------------' + DateOfBirth + ' ---> ' + this.DateOfBirth);
};

export const convertUnixTimestampToDateReturnDate = (DateOfBirth: string | null | undefined): Date | null => {
    let string = convertUnixTimestampToDate(DateOfBirth);
    if(string) {
        return new Date(string);
    }
    return null;
}