import React from 'react';
import {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormCurrencyInput from "../Form/FormCurrencyInput";
import EFinanceFieldTypes from "../../models/Case/UpdateCase/FieldTypes/EFinanceFieldTypes";
import FormReadonly from "../Form/FormReadonlyInput";
import FormInput from "../Form/FormInput";



export default function Summary() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, saveSuccess, setUnsavedChanges} = useContext(UpdateCaseContext);
  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    if (!currentCase.CaseApp) {
      console.error("currentCase.CaseApp is null or undefined.");
      return;
    }

    switch (fieldName) {

      case EFinanceFieldTypes.NetClaim:
        currentCase.CaseApp.NetClaim = Number(value);
        break;
      case ESummaryFieldTypes.DailyRate:
        currentCase.CaseApp.InterestDaily = value;
      break;
      case ESummaryFieldTypes.YearlyRate:
          currentCase.CaseApp.InterestYearly = Number(value);
        break;
        case ESummaryFieldTypes.MonthlyRate:
          currentCase.CaseApp.InterestMonthly = value;
        break;
        case ESummaryFieldTypes.Term:
          currentCase.CaseApp.Term = value;
        break;
        case ESummaryFieldTypes.Provider:
          currentCase.CaseApp.Provider = value;
        break;
        case ESummaryFieldTypes.MatterType:
          currentCase.CaseApp.Provider = value;
        break;
        case ESummaryFieldTypes.ProviderRef:
          currentCase.CaseApp.Provider = value;
        break;
        case ESummaryFieldTypes.CaseStatus:
          currentCase.CaseApp.Provider = value;
        break;
        case ESummaryFieldTypes.SourceName:
          currentCase.CaseApp.Provider = value;
        break;
        case ESummaryFieldTypes.ProviderCaseRef:
          currentCase.CaseApp.Provider = value;
        break;
        case ESummaryFieldTypes.AgreementStartDate:
          currentCase.CaseApp.Provider = value;
        break;
        case ESummaryFieldTypes.StatusDate:
          currentCase.CaseApp.Provider = value;
        break;

    }
    setCase(currentCase);
    updateTabsModified("Summary", hasValueChanged);
    setUnsavedChanges(hasValueChanged); 
  }

  return (
    <div className="m-2">

    <div className='form-layout'>
        <div className={'form-column'}>
            <FormReadonly fieldName={`Title`} label={`Title`} value={currentCase.CaseApp?.MainApplicant?.Title ?? ""} />
            <FormReadonly fieldName={`Surname`} label={`Surname`} value={currentCase.CaseApp?.MainApplicant?.Surname ?? ""} />
            <FormReadonly fieldName={`Telephone`} label={`Telephone`} value={currentCase.CaseApp?.MainApplicant?.Telephone ?? ""} />
            <FormReadonly fieldName={`EmailAddress`} label={`Email Address`} value={currentCase.CaseApp?.MainApplicant?.EmailAddress ?? ""} />
        </div>
        <div className={'form-column'}>
            <FormReadonly fieldName={`Firstname`} label={`Firstname`} value={currentCase.CaseApp?.MainApplicant?.Firstname ?? ""} />
            <FormReadonly fieldName={`DateOfBirth`} label={`Date of birth`} value={currentCase.CaseApp?.MainApplicant?.DateOfBirth ?? ""} />
            <FormReadonly fieldName={`Mobile`} label={`Mobile`} value={currentCase.CaseApp?.MainApplicant?.Mobile ?? ""} />
            <FormReadonly fieldName={`CurrentAddress`} label={`Current Address`} value={currentCase.CaseApp?.MainApplicant?.CurrentAddress ?? ""} />
        </div>
    </div>

    <hr />

    {currentCase.CaseApp?.AdditionalApplicants?.map((applicant, index) => (
        <div className='form-layout'>
            <div className={'form-column'}>
                <FormReadonly fieldName={`Title`} label={`Title`} value={applicant?.Title ?? ""} />
                <FormReadonly fieldName={`Surname`} label={`Surname`} value={applicant?.Surname ?? ""} />
                <FormReadonly fieldName={`Telephone`} label={`Telephone`} value={applicant?.Telephone ?? ""} />
                <FormReadonly fieldName={`EmailAddress`} label={`Email Address`} value={applicant?.EmailAddress ?? ""} />
            </div>
            <div className={'form-column'}>
                <FormReadonly fieldName={`Firstname`} label={`Firstname`} value={applicant?.Firstname ?? ""} />
                <FormReadonly fieldName={`DateOfBirth`} label={`Date of birth`} value={applicant?.DateOfBirth ?? ""} />
                <FormReadonly fieldName={`Mobile`} label={`Mobile`} value={applicant?.Mobile ?? ""} />
                <FormReadonly fieldName={`CurrentAddress`} label={`Current Address`} value={applicant?.CurrentAddress ?? ""} />
            </div>
        </div>
    ))}
    <div className="form-layout">
    </div>
    <hr />
    <div className="form-layout">
      <div className="form-column">
      <FormReadonly fieldName={ESummaryFieldTypes.MatterType.toString()} label="Matter Type"  value={currentCase.CaseApp?.MatterType?.toString() ?? ""} />
      <FormReadonly fieldName={ESummaryFieldTypes.ProviderRef.toString()} label="Provider Ref"  value={currentCase.CaseApp?.ProviderRef?.toString() ?? ""} />
      <FormCurrencyInput readonly={true} fieldName={EFinanceFieldTypes.NetClaim.toString()} label="Total Loan" value={currentCase.CaseApp?.NetClaim?.toString() ?? ""} setValue={handleState} />
      <FormDecimalInput readonly={true} fieldName={ESummaryFieldTypes.DailyRate.toString()} label="Daily rate"  value={currentCase.CaseApp?.InterestDaily?.toString() ?? ""} setValue={handleState}/>
      <FormDecimalInput readonly={true} fieldName={ESummaryFieldTypes.YearlyRate.toString()} label="Yearly rate" value={currentCase.CaseApp?.InterestYearly?.toString() ?? ""} setValue={handleState} />
      <FormReadonly fieldName={ESummaryFieldTypes.CaseStatus.toString()} label="Case Status"  value={currentCase.CaseApp?.CaseStatus?.toString() ?? ""} />
      <FormReadonly fieldName={ESummaryFieldTypes.SourceName.toString()} label="Source Name"  value={currentCase.CaseApp?.SourceName?.toString() ?? ""} />

    </div>
        <div className="form-column">
        <FormReadonly fieldName={ESummaryFieldTypes.Provider.toString()} label="Provider"  value={currentCase.CaseApp?.Provider?.toString() ?? ""} />
        <FormReadonly fieldName={ESummaryFieldTypes.ProviderCaseRef.toString()} label="Provider Case Ref"  value={currentCase.CaseApp?.ProviderCaseRef?.toString() ?? ""} />
        <FormReadonly fieldName={ESummaryFieldTypes.Term.toString()} label="Term"  value={currentCase.CaseApp?.Term?.toString() ?? ""} />
        <FormDecimalInput readonly={true} fieldName={ESummaryFieldTypes.MonthlyRate.toString()} label="Monthly rate"  value={currentCase.CaseApp?.InterestMonthly?.toString() ?? ""} setValue={handleState}/>
        {/* Date fix Needed */}
        <FormReadonly fieldName={ESummaryFieldTypes.AgreementStartDate.toString()} label="Agreement Start Date"  value={currentCase.CaseApp?.AgreementStartDate?.toString() ?? ""} />
        <FormReadonly fieldName={ESummaryFieldTypes.StatusDate.toString()} label="Status Date"  value={currentCase.CaseApp?.StatusDate?.toString() ?? ""} />

      </div>
    </div>

    </div>

    
  );
}
