import {format} from "date-fns";
import moment from "moment";
import {convertUnixTimestampToDate} from "../../utils/commonUtils";

export class Applicant {
    Title: string | null;
    Firstname: string;
    Surname: string | null;
    DateOfBirth: string | null;
    Mobile: string | null;
    CurrentAddress: string | null;
    Telephone: string | null;
    EmailAddress: string | null;
    App2ID?: number;
    constructor( Title: string | null,
                 Firstname: string,
                 Surname: string | null,
                 DateOfBirth?: string,
                 Mobile?: string,
                 CurrentAddress?: string,
                 Telephone?: string,
                 EmailAddress?: string,
                 App2ID?: number)
    {
        this.Title = Title;
        this.Firstname = Firstname;
        this.Surname = Surname;
        this.App2ID = App2ID;
        this.DateOfBirth = convertUnixTimestampToDate(DateOfBirth);
        this.Mobile = Mobile ?? "";
        this.CurrentAddress = CurrentAddress ?? "";
        this.Telephone = CurrentAddress ?? "";
        this.EmailAddress = CurrentAddress ?? "";
    }
}