import {
  BrowserRouter as Router,
  Routes,
  Route,
  } from 'react-router-dom';
  import Private from './pages/Private';
  import SingleCase from './pages/SingleCase';
  import Login from './pages/Login';
  import Cases from './pages/Cases';
import './App.css';

function App() {
  return (
    <Router>
		<Routes>
			<Route path="/" element={
        <Private>
          <Cases/>
        </Private>
      }/>
			<Route path="/login" element={<Login/>} />
      <Route path="/singlecase/:id" element={
        <Private>
          <SingleCase />
        </Private>
			} />
		</Routes>
	</Router>
  );
}

export default App;
