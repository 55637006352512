import React from 'react';
import {useContext, useEffect, useState} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import FormReadonly from "../Form/FormReadonlyInput";
import FormReadonlyWithABadge from "../Form/FormReadonlyWithABadge";
import FormCurrencyInput from "../Form/FormCurrencyInput";
import FormInput from "../Form/FormInput";


export default function CaseAssessor() {
  const {currentCase, setCase, customerReference, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  const [borrowingName, setBorrowingName] = useState<string[]>([]);
  const [directorsShareholders, setDirectorsShareholders] = useState('');
  const [surveyRequestedDate, setSurveyRequestedDate] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');

  useEffect(() => {

      if (currentCase?.CaseApp) {
        const name = currentCase.CaseApp.getBorrowingName();
        setBorrowingName(name);

        let directorsAndShareholders = currentCase.CaseApp.getDirectorsShareholdersCompanyNumber();

        const processedDirectorsAndShareholders = directorsAndShareholders.split(', ').map(entry => {
            const parts = entry.split(' ');
            return parts.slice(0, parts.length - 1).join(' ');
        }).join(', ');

        setDirectorsShareholders(processedDirectorsAndShareholders);

        const surveyDate = currentCase.CaseApp.getSurveyRequestedDate();
        setSurveyRequestedDate(surveyDate);

        const companyNumbers = directorsAndShareholders.split(', ').map(entry => {
            const parts = entry.split(' ');
            return parts.length > 1 ? parts[parts.length - 1] : 'n/a';
        });

        setCompanyNumber(companyNumbers.join(', '));
    }
  }, [currentCase?.CaseApp]);


  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    switch (fieldName) {
      case ESummaryFieldTypes.DynamicDataField2:
        currentCase.UpdateDynamicDataField(2, value);
        break;
      case ESummaryFieldTypes.DynamicDataField3:
        currentCase.UpdateDynamicDataField(3, value);
        break;

      case ESummaryFieldTypes.DynamicDataField6:
        currentCase.UpdateDynamicDataField(6, value);
        break;

      case ESummaryFieldTypes.DynamicDataField7:
        currentCase.UpdateDynamicDataField(7, value);
        break;

      case ESummaryFieldTypes.DynamicDataField5:
        currentCase.UpdateDynamicDataField(5, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ1:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(1, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ62:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(62, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ63:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(63, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ64:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(64, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ65:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(65, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ66:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(66, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ67:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(67, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ68:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(68, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ69:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(69, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ70:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(70, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ71:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(71, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ72:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(72, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ73:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(73, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ74:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(74, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ75:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(75, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ76:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(76, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ77:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(77, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ78:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(78, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ79:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(79, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ80:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(80, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ81:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(81, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ82:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(82, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ83:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(83, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ84:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(84, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ85:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(85, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ86:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(86, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ87:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(87, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ88:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(88, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ89:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(89, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ90:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(90, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ91:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(91, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ92:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(92, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ93:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(93, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ94:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(94, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ95:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(95, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ96:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(96, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ97:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(97, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ98:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(98, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ99:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(99, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ100:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(100, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ101:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(101, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ102:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(102, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ103:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(103, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ104:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(104, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ105:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(105, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ106:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(106, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ107:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(107, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ108:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(108, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ109:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(109, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ110:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(110, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ111:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(111, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ112:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(112, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ113:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(113, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ114:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(114, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ115:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(115, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ116:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(116, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ117:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(117, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ118:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(118, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ119:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(119, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ120:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(120, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ121:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(121, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ122:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(122, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ123:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(123, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("Underwriting", hasValueChanged);
    setUnsavedChanges(hasValueChanged);
  }

  return (
    <div className="form-layout">
        <div className="form-column form-column-dynamic">
            {currentCaseAssessorQuestions?.Records?.map((entry, index) => (
                <div key={index} className={'w'}>
                    {(() => {
                        switch(entry.QuestionType) {
                            case "Data Table":
                                return <FormSelect
                                    fieldName={entry.Question}
                                    label={entry.Question}
                                    value={entry.Answer}
                                    setValue={handleState}
                                    values={entry?.AnswerList ?? []}
                                />;
                            case "Text":
                                return <FormTextArea
                                    fieldName={entry.Question}
                                    label={entry.Question}
                                    value={entry.Answer}
                                    setValue={handleState} />;
                            case "Date":
                                return <FormDateInput
                                    fieldName={entry.Question}
                                    label={entry.Question}
                                    value={currentCaseAssessorQuestions.ConvertDate(entry.Answer)}
                                    setValue={handleState} />;
                            case "Currency":
                                return <FormCurrencyInput
                                    fieldName={entry.Question}
                                    label={entry.Question}
                                    value={entry.Answer}
                                    setValue={handleState} />
                                    ;
                            case "Yes/No":
                                      return <FormSelect
                                          fieldName={entry.Question}
                                          label={entry.Question}
                                          value={entry.Answer}
                                          setValue={handleState}
                                          values={entry?.AnswerList ?? []}
                                      />;
                            default:
                                return <span>{entry.QuestionType}</span>;
                        }
                    })()}
                </div>
            ))}
        </div>
    </div>
  );
}
