import { AdditionalFee } from "./AdditionalFee";
import { Applicant } from "./Applicant";
import DynamicFieldDetail from "./DynamicFieldDetail";
import ApplicationProgressDates from "./GetCase/ApplicationProgressDates";
import { PaymentPlanDetail } from "./PaymentPlanDetail";
import { MultiContact } from "./MultiContact";
import { format } from 'date-fns';
import { ValuationDetails } from "./ValuationDetails";
import SecurityAddress from "./GetCase/SecurityAddress";
import moment from "moment";
import {convertUnixTimestampToDate} from "../../utils/commonUtils";


export default class CaseApp {
    public ApplicantID: string;
    public AvoidUpdatingBlankData: boolean;
    public IsUpdateCaseCustomer: boolean;
    public DynamicFieldDetail: DynamicFieldDetail[];
    public CustomerInitialFee?: string;
    public PaymentPlanDetail: PaymentPlanDetail;
    public CustomerCompletionFee?: number;
    public RolledUpInterestForTerm?: number;
    public BrokerAdminFee?: number;
    public ValuationFee?: number;
    public LegalFee?: number;
    public Deposit?: number;
    public OtherFee?: number;
    public TotalRepaymentAmount?: string;
    public AdditionalFee: AdditionalFee[];
    public LastLoanInterestRaisedAmount? : string;
    public TotalLoanInterestRaisedAmount? : string;
    public ApplicationProgressDates: ApplicationProgressDates;
    public InterestDaily?: string;
    public InterestMonthly?: string;
    public InterestYearly? : number;
    public Term?: string;
    public Provider?: string;
    public MatterType?: string;
    public ProviderRef?: string;
    public CaseStatus?: string;
    public SourceName?: string;
    public ProviderCaseRef?: string;
    public AgreementStartDate?: string;
    public StatusDate?: string;
    public MainApplicant?: Applicant;
    public AdditionalApplicants?: Applicant[];
    public MultiContacts?: MultiContact[];
    public ValuationDetails: ValuationDetails[] = [];
    public SecurityAddress: SecurityAddress[] = [];
    public LGDVPer?: string;
    public NetClaim?: number;
    public OriginalLoanAmount?: number;

    constructor(
        ApplicantID: string = '',
        DynamicFieldDetails: DynamicFieldDetail[] = [],
        SecurityAddress: SecurityAddress[],
        ValuationDetails: ValuationDetails[],
        ApplicationProgressDates: ApplicationProgressDates,
        CustomerInitialFee: string = '',
        PaymentPlanDetail: PaymentPlanDetail,
        CustomerCompletionFee?: number,
        RolledUpInterestForTerm?: number,
        BrokerAdminFee?: number,
        ValuationFee?: number,
        LegalFee?: number,
        Deposit?: number,
        OtherFee?: number,
        TotalRepaymentAmount?: string,
        AdditionalFee: AdditionalFee[] = [],
        LastLoanInterestRaisedAmount? : string,
        TotalLoanInterestRaisedAmount? : string,
        InterestDaily? : string,
        InterestMonthly? : string,
        InterestYearly? : number,
        Term? : string,
        MatterType? : string,
        ProviderRef? : string,
        CaseStatus? : string,
        SourceName? : string,
        Provider? : string,
        ProviderCaseRef? : string,
        AgreementStartDate? : string,
        StatusDate? : string,
        MainApplicant?: Applicant,
        AdditionalApplicants?: Applicant[],
        MultiContacts?: MultiContact[] ,
        LGDVPer?: string,
        NetClaim?: number,
        OriginalLoanAmount?: number,
    ) {
        this.ApplicantID = ApplicantID;
        this.AvoidUpdatingBlankData = true;
        this.IsUpdateCaseCustomer = true;
        this.DynamicFieldDetail = DynamicFieldDetails;
        this.CustomerInitialFee = CustomerInitialFee;
        this.PaymentPlanDetail = PaymentPlanDetail;
        this.CustomerCompletionFee = CustomerCompletionFee;
        this.RolledUpInterestForTerm = RolledUpInterestForTerm;
        this.BrokerAdminFee = BrokerAdminFee;
        this.ValuationFee = ValuationFee;
        this.LegalFee = LegalFee;
        this.Deposit = Deposit;
        this.OtherFee = OtherFee;
        this.TotalRepaymentAmount = TotalRepaymentAmount;
        this.AdditionalFee = AdditionalFee;
        this.LastLoanInterestRaisedAmount = LastLoanInterestRaisedAmount;
        this.TotalLoanInterestRaisedAmount = TotalLoanInterestRaisedAmount;
        this.ApplicationProgressDates = ApplicationProgressDates;
        this.InterestDaily = InterestDaily;
        this.InterestMonthly = InterestMonthly;
        this.InterestYearly = InterestYearly;
        this.Term = Term;
        this.MatterType = MatterType;
        this.ProviderRef = ProviderRef;
        this.CaseStatus = CaseStatus;
        this.SourceName = SourceName;
        this.Provider = Provider;
        this.ProviderCaseRef = ProviderCaseRef;
        this.AgreementStartDate = convertUnixTimestampToDate(AgreementStartDate);
        this.StatusDate = convertUnixTimestampToDate(StatusDate);
        this.MainApplicant = MainApplicant;
        this.AdditionalApplicants = AdditionalApplicants;
        this.MultiContacts = MultiContacts;
        this.ValuationDetails = ValuationDetails;
        this.SecurityAddress = SecurityAddress;
        this.ValuationDetails = ValuationDetails;
        this.LGDVPer = LGDVPer;
        this.NetClaim = NetClaim;
        this.OriginalLoanAmount = OriginalLoanAmount;
    }

    public getBorrowingName(): string[] {
        let names = [];
    
        if (this.MainApplicant) {
            const mainTitle = this.MainApplicant.Title ? this.MainApplicant.Title + ". " : "";
            const mainNameParts = [mainTitle, this.MainApplicant.Firstname, this.MainApplicant.Surname].filter(Boolean);
            if (mainNameParts.length > 0) {
                names.push(mainNameParts.join(" "));
            }
        }
    
        this.AdditionalApplicants?.forEach(applicant => {
            const additionalTitle = applicant?.Title ? `${applicant.Title}. ` : "";
            names.push(`${additionalTitle}${applicant?.Firstname} ${applicant?.Surname}`);
        });
    
        return names.length > 0 ? names : ['n/a']; 
    }

    public getDirectorsShareholdersCompanyNumber(): string {
        if (!this.MultiContacts || this.MultiContacts.length === 0) {
            return 'n/a';
        }
    
        return this.MultiContacts.map(contact => {
            const customValue = contact.CustomFields.find(field => field.CustomFieldNo === "1")?.CustomFieldValue || 'n/a';
            const firstname = contact.Firstname || "";
            const surname = contact.Surname || "";
            return `${firstname} ${surname} ${customValue}`.trim();
        }).join(', ');
    }

    public getSurveyRequestedDate(): string {
        if (this.ValuationDetails && this.ValuationDetails.length > 0) {
            const surveyRequestedRaw = this.ValuationDetails[0].SurveyRequested;
    
            if (surveyRequestedRaw) {
                const match = surveyRequestedRaw.match(/\/Date\((\d+)\)\//);
                if (match) {
                    const timestamp = parseInt(match[1], 10);
                    return format(new Date(timestamp), 'dd-MM-yyyy');
                }
            }
        }
        return 'n/a';
    }

    public getAdditionalFee(feeNumber: number): number | null {
        const fee = this.AdditionalFee.find(f => f.FeeNumber === feeNumber);
        return fee ? fee.Fee : null;
    }

    public updateAdditionalFee(feeNumber: number, value: number) {
        var additionalFee = this.AdditionalFee.find((z) => z.FeeNumber == feeNumber);
        if (additionalFee)
            additionalFee.Fee = value;
        else
          this.AdditionalFee.push(new AdditionalFee(feeNumber, value));
    }

    public GetSecurityAddress() : SecurityAddress[] {
        return this.SecurityAddress.length > 0 ? this.SecurityAddress : [new SecurityAddress()];
    }
    
    public GetValuationDetails() : ValuationDetails {
        return this.ValuationDetails.length > 0 ? this.ValuationDetails[0] : new ValuationDetails();
    }
}