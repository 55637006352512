enum ESummaryFieldTypes {
  DynamicDataField2 = "DynamicDataField2",
  DynamicDataField3 = "DynamicDataField3",
  DynamicDataField5 = "DynamicDataField5",
  DynamicDataField6 = "DynamicDatafield6",
  DynamicDataField7 = "DynamicDatafield7",
  DynamicDataField8 = "DynamicDatafield8",
  DynamicDataField9 = "DynamicDatafield9",
  CaseAssessorQ1 = "CaseAssessorQ1",
  CaseAssessorQ2 = "CaseAssessorQ2",
  CaseAssessorQ3 = "CaseAssessorQ3",
  CaseAssessorQ4 = "CaseAssessorQ4",
  CaseAssessorQ5 = "CaseAssessorQ5",
  CaseAssessorQ6 = "CaseAssessorQ6",
  CaseAssessorQ7 = "CaseAssessorQ7",
  CaseAssessorQ8 = "CaseAssessorQ8",
  CaseAssessorQ9 = "CaseAssessorQ9",
  CaseAssessorQ10 = "CaseAssessorQ10",
  CaseAssessorQ11 = "CaseAssessorQ11",
  CaseAssessorQ12 = "CaseAssessorQ12",
  CaseAssessorQ13 = "CaseAssessorQ13",
  CaseAssessorQ14 = "CaseAssessorQ14",
  CaseAssessorQ15 = "CaseAssessorQ15",
  CaseAssessorQ16 = "CaseAssessorQ16",
  CaseAssessorQ17 = "CaseAssessorQ17",
  CaseAssessorQ18 = "CaseAssessorQ18",
  CaseAssessorQ19 = "CaseAssessorQ19",
  CaseAssessorQ20 = "CaseAssessorQ20",
  CaseAssessorQ21 = "CaseAssessorQ21",
  CaseAssessorQ22 = "CaseAssessorQ22",
  CaseAssessorQ23 = "CaseAssessorQ23",
  CaseAssessorQ24 = "CaseAssessorQ24",
  CaseAssessorQ25 = "CaseAssessorQ25",
  CaseAssessorQ26 = "CaseAssessorQ26",
  CaseAssessorQ27 = "CaseAssessorQ27",
  CaseAssessorQ28 = "CaseAssessorQ28",
  CaseAssessorQ29 = "CaseAssessorQ29",
  CaseAssessorQ30 = "CaseAssessorQ30",
  CaseAssessorQ31 = "CaseAssessorQ31",
  CaseAssessorQ32 = "CaseAssessorQ32",
  CaseAssessorQ33 = "CaseAssessorQ33",
  CaseAssessorQ34 = "CaseAssessorQ34",
  CaseAssessorQ35 = "CaseAssessorQ35",
  CaseAssessorQ36 = "CaseAssessorQ36",
  CaseAssessorQ37 = "CaseAssessorQ37",
  CaseAssessorQ38 = "CaseAssessorQ38",
  CaseAssessorQ39 = "CaseAssessorQ39",
  CaseAssessorQ40 = "CaseAssessorQ40",
  CaseAssessorQ41 = "CaseAssessorQ41",
  CaseAssessorQ42 = "CaseAssessorQ42",
  CaseAssessorQ43 = "CaseAssessorQ43",
  CaseAssessorQ44 = "CaseAssessorQ44",
  CaseAssessorQ45 = "CaseAssessorQ45",
  CaseAssessorQ46 = "CaseAssessorQ46",
  CaseAssessorQ47 = "CaseAssessorQ47",
  CaseAssessorQ48 = "CaseAssessorQ48",
  CaseAssessorQ49 = "CaseAssessorQ49",
  CaseAssessorQ50 = "CaseAssessorQ50",
  CaseAssessorQ51 = "CaseAssessorQ51",
  CaseAssessorQ52 = "CaseAssessorQ52",
  CaseAssessorQ53 = "CaseAssessorQ53",
  CaseAssessorQ54 = "CaseAssessorQ54",
  CaseAssessorQ55 = "CaseAssessorQ55",
  CaseAssessorQ56 = "CaseAssessorQ56",
  CaseAssessorQ57 = "CaseAssessorQ57",
  CaseAssessorQ58 = "CaseAssessorQ58",
  CaseAssessorQ59 = "CaseAssessorQ59",
  CaseAssessorQ60 = "CaseAssessorQ60",
  CaseAssessorQ61 = "CaseAssessorQ61",
  CaseAssessorQ62 = "CaseAssessorQ62",
  CaseAssessorQ63 = "CaseAssessorQ63",
  CaseAssessorQ64 = "CaseAssessorQ64",
  CaseAssessorQ65 = "CaseAssessorQ65",
  CaseAssessorQ66 = "CaseAssessorQ66",
  CaseAssessorQ67 = "CaseAssessorQ67",
  CaseAssessorQ68 = "CaseAssessorQ68",
  CaseAssessorQ69 = "CaseAssessorQ69",
  CaseAssessorQ70 = "CaseAssessorQ70",
  CaseAssessorQ71 = "CaseAssessorQ71",
  CaseAssessorQ72 = "CaseAssessorQ72",
  CaseAssessorQ73 = "CaseAssessorQ73",
  CaseAssessorQ74 = "CaseAssessorQ74",
  CaseAssessorQ75 = "CaseAssessorQ75",
  CaseAssessorQ76 = "CaseAssessorQ76",
  CaseAssessorQ77 = "CaseAssessorQ77",
  CaseAssessorQ78 = "CaseAssessorQ78",
  CaseAssessorQ79 = "CaseAssessorQ79",
  CaseAssessorQ80 = "CaseAssessorQ80",
  CaseAssessorQ81 = "CaseAssessorQ81",
  CaseAssessorQ82 = "CaseAssessorQ82",
  CaseAssessorQ83 = "CaseAssessorQ83",
  CaseAssessorQ84 = "CaseAssessorQ84",
  CaseAssessorQ85 = "CaseAssessorQ85",
  CaseAssessorQ86 = "CaseAssessorQ86",
  CaseAssessorQ87 = "CaseAssessorQ87",
  CaseAssessorQ88 = "CaseAssessorQ88",
  CaseAssessorQ89 = "CaseAssessorQ89",
  CaseAssessorQ90 = "CaseAssessorQ90",
  CaseAssessorQ91 = "CaseAssessorQ91",
  CaseAssessorQ92 = "CaseAssessorQ92",
  CaseAssessorQ93 = "CaseAssessorQ93",
  CaseAssessorQ94 = "CaseAssessorQ94",
  CaseAssessorQ95 = "CaseAssessorQ95",
  CaseAssessorQ96 = "CaseAssessorQ96",
  CaseAssessorQ97 = "CaseAssessorQ97",
  CaseAssessorQ98 = "CaseAssessorQ98",
  CaseAssessorQ99 = "CaseAssessorQ99",
  CaseAssessorQ100 = "CaseAssessorQ100",
  CaseAssessorQ101 = "CaseAssessorQ101",
  CaseAssessorQ102 = "CaseAssessorQ102",
  CaseAssessorQ103 = "CaseAssessorQ103",
  CaseAssessorQ104 = "CaseAssessorQ104",
  CaseAssessorQ105 = "CaseAssessorQ105",
  CaseAssessorQ106 = "CaseAssessorQ106",
  CaseAssessorQ107 = "CaseAssessorQ107",
  CaseAssessorQ108 = "CaseAssessorQ108",
  CaseAssessorQ109 = "CaseAssessorQ109",
  CaseAssessorQ110 = "CaseAssessorQ110",
  CaseAssessorQ111 = "CaseAssessorQ111",
  CaseAssessorQ112 = "CaseAssessorQ112",
  CaseAssessorQ113 = "CaseAssessorQ113",
  CaseAssessorQ114 = "CaseAssessorQ114",
  CaseAssessorQ115 = "CaseAssessorQ115",
  CaseAssessorQ116 = "CaseAssessorQ116",
  CaseAssessorQ117 = "CaseAssessorQ117",
  CaseAssessorQ118 = "CaseAssessorQ118",
  CaseAssessorQ119 = "CaseAssessorQ119",
  CaseAssessorQ120 = "CaseAssessorQ120",
  CaseAssessorQ121 = "CaseAssessorQ121",
  CaseAssessorQ122 = "CaseAssessorQ122",
  CaseAssessorQ123 = "CaseAssessorQ123",
  CaseAssessorQ124 = "CaseAssessorQ124",
  CaseAssessorQ125 = "CaseAssessorQ125",
  CaseAssessorQ126 = "CaseAssessorQ126",
  CaseAssessorQ127 = "CaseAssessorQ127",
  CaseAssessorQ128 = "CaseAssessorQ128",
  CaseAssessorQ129 = "CaseAssessorQ129",
  CaseAssessorQ130 = "CaseAssessorQ130",
  CaseAssessorQ131 = "CaseAssessorQ131",
  CaseAssessorQ132 = "CaseAssessorQ132",
  CaseAssessorQ133 = "CaseAssessorQ133",
  CaseAssessorQ134 = "CaseAssessorQ134",
  CaseAssessorQ135 = "CaseAssessorQ135",
  CaseAssessorQ136 = "CaseAssessorQ136",
  CaseAssessorQ137 = "CaseAssessorQ137",
  CaseAssessorQ138 = "CaseAssessorQ138",
  CaseAssessorQ139 = "CaseAssessorQ139",
  CaseAssessorQ140 = "CaseAssessorQ140",
  CaseAssessorQ141 = "CaseAssessorQ141",
  CaseAssessorQ142 = "CaseAssessorQ142",
  CaseAssessorQ143 = "CaseAssessorQ143",
  CaseAssessorQ144 = "CaseAssessorQ144",
  CaseAssessorQ145 = "CaseAssessorQ145",
  CaseAssessorQ146 = "CaseAssessorQ146",
  CaseAssessorQ147 = "CaseAssessorQ147",
  CaseAssessorQ148 = "CaseAssessorQ148",
  CaseAssessorQ149 = "CaseAssessorQ149",
  CaseAssessorQ150 = "CaseAssessorQ150",
  CaseAssessorQ151 = "CaseAssessorQ151",
  CaseAssessorQ152 = "CaseAssessorQ152",
  CaseAssessorQ153 = "CaseAssessorQ153",
  CaseAssessorQ154 = "CaseAssessorQ154",
  CaseAssessorQ155 = "CaseAssessorQ155",
  CaseAssessorQ156 = "CaseAssessorQ156",
  CaseAssessorQ157 = "CaseAssessorQ157",
  CaseAssessorQ158 = "CaseAssessorQ158",
  CaseAssessorQ159 = "CaseAssessorQ159",
  CaseAssessorQ160 = "CaseAssessorQ160",
  CaseAssessorQ161 = "CaseAssessorQ161",
  CaseAssessorQ162 = "CaseAssessorQ162",
  CaseAssessorQ163 = "CaseAssessorQ163",
  CaseAssessorQ164 = "CaseAssessorQ164",
  CaseAssessorQ165 = "CaseAssessorQ165",
  CaseAssessorQ166 = "CaseAssessorQ166",
  CaseAssessorQ167 = "CaseAssessorQ167",
  CaseAssessorQ168 = "CaseAssessorQ168",
  CaseAssessorQ170 = "CaseAssessorQ170",
  CaseAssessorQ171 = "CaseAssessorQ171",
  CaseAssessorQ172 = "CaseAssessorQ172",
  CaseAssessorQ173 = "CaseAssessorQ173",
  CaseAssessorQ174 = "CaseAssessorQ174",
  CaseAssessorQ175 = "CaseAssessorQ175",
  CaseAssessorQ176 = "CaseAssessorQ176",
  CaseAssessorQ177 = "CaseAssessorQ177",
  CaseAssessorQ178 = "CaseAssessorQ178",
  CaseAssessorQ179 = "CaseAssessorQ179",
  CaseAssessorQ180 = "CaseAssessorQ180",
  CaseAssessorQ181 = "CaseAssessorQ181",
  CaseAssessorQ182 = "CaseAssessorQ182",
  CaseAssessorQ183 = "CaseAssessorQ183",
  CaseAssessorQ184 = "CaseAssessorQ184",
  CaseAssessorQ185 = "CaseAssessorQ185",
  CaseAssessorQ186 = "CaseAssessorQ186",
  CaseAssessorQ187 = "CaseAssessorQ187",
  CaseAssessorQ188 = "CaseAssessorQ188",
  CaseAssessorQ189 = "CaseAssessorQ189",
  CaseAssessorQ190 = "CaseAssessorQ190",
  CaseAssessorQ191 = "CaseAssessorQ191",
  CaseAssessorQ192 = "CaseAssessorQ192",
  CaseAssessorQ193 = "CaseAssessorQ193",
  CaseAssessorQ194 = "CaseAssessorQ194",
  CaseAssessorQ195 = "CaseAssessorQ195",
  CaseAssessorQ196 = "CaseAssessorQ196",
  CaseAssessorQ197 = "CaseAssessorQ197",
  CaseAssessorQ198 = "CaseAssessorQ198",
  CaseAssessorQ199 = "CaseAssessorQ199",
  CaseAssessorQ200 = "CaseAssessorQ200",
  CaseAssessorQ201 = "CaseAssessorQ201",
  CaseAssessorQ202 = "CaseAssessorQ202",
  CaseAssessorQ203 = "CaseAssessorQ203",
  CaseAssessorQ204 = "CaseAssessorQ204",
  CaseAssessorQ205 = "CaseAssessorQ205",
  CaseAssessorQ206 = "CaseAssessorQ206",
  CaseAssessorQ207 = "CaseAssessorQ207",
  CaseAssessorQ208 = "CaseAssessorQ208",
  CaseAssessorQ209 = "CaseAssessorQ209",
  CaseAssessorQ210 = "CaseAssessorQ210",
  CaseAssessorQ211 = "CaseAssessorQ211",
  CaseAssessorQ212 = "CaseAssessorQ212",
  CaseAssessorQ213 = "CaseAssessorQ213",
  CaseAssessorQ214 = "CaseAssessorQ214",
  CaseAssessorQ215 = "CaseAssessorQ215",
  CaseAssessorQ216 = "CaseAssessorQ216",
  CaseAssessorQ217 = "CaseAssessorQ217",
  CaseAssessorQ218 = "CaseAssessorQ218",
  CaseAssessorQ219 = "CaseAssessorQ219",
  CaseAssessorQ220 = "CaseAssessorQ220",
  CaseAssessorQ221 = "CaseAssessorQ221",
  CaseAssessorQ222 = "CaseAssessorQ222",
  CaseAssessorQ223 = "CaseAssessorQ223",
  CaseAssessorQ224 = "CaseAssessorQ224",
  CaseAssessorQ225 = "CaseAssessorQ225",
  CaseAssessorQ226 = "CaseAssessorQ226",
  CaseAssessorQ227 = "CaseAssessorQ227",
  CaseAssessorQ228 = "CaseAssessorQ228",
  CaseAssessorQ229 = "CaseAssessorQ229",
  CaseAssessorQ230 = "CaseAssessorQ230",
  CaseAssessorQ231 = "CaseAssessorQ231",
  CaseAssessorQ232 = "CaseAssessorQ232",
  CaseAssessorQ233 = "CaseAssessorQ233",
  CaseAssessorQ234 = "CaseAssessorQ234",
  CaseAssessorQ235 = "CaseAssessorQ235",
  CaseAssessorQ236 = "CaseAssessorQ236",
  CaseAssessorQ237 = "CaseAssessorQ237",
  CaseAssessorQ238 = "CaseAssessorQ238",
  CaseAssessorQ239 = "CaseAssessorQ239",
  CaseAssessorQ240 = "CaseAssessorQ240",
  CaseAssessorQ241 = "CaseAssessorQ241",
  CaseAssessorQ242 = "CaseAssessorQ242",
  CaseAssessorQ243 = "CaseAssessorQ243",
  CaseAssessorQ244 = "CaseAssessorQ244",
  CaseAssessorQ245 = "CaseAssessorQ245",
  CaseAssessorQ246 = "CaseAssessorQ246",
  CaseAssessorQ247 = "CaseAssessorQ247",
  CaseAssessorQ248 = "CaseAssessorQ248",
  CaseAssessorQ249 = "CaseAssessorQ249",
  CaseAssessorQ250 = "CaseAssessorQ250",
  CaseAssessorQ251 = "CaseAssessorQ251",
  CaseAssessorQ252 = "CaseAssessorQ252",
  CaseAssessorQ253 = "CaseAssessorQ253",
  CaseAssessorQ254 = "CaseAssessorQ254",
  CaseAssessorQ255 = "CaseAssessorQ255",
  CaseAssessorQ256 = "CaseAssessorQ256",
  CaseAssessorQ257 = "CaseAssessorQ257",
  CaseAssessorQ258 = "CaseAssessorQ258",
  CaseAssessorQ259 = "CaseAssessorQ259",
  CaseAssessorQ260 = "CaseAssessorQ260",
  CaseAssessorQ261 = "CaseAssessorQ261",
  CaseAssessorQ262 = "CaseAssessorQ262",
  CaseAssessorQ263 = "CaseAssessorQ263",
  CaseAssessorQ264 = "CaseAssessorQ264",
  CaseAssessorQ265 = "CaseAssessorQ265",
  CaseAssessorQ266 = "CaseAssessorQ266",
  CaseAssessorQ267 = "CaseAssessorQ267",
  CaseAssessorQ268 = "CaseAssessorQ268",
  CaseAssessorQ269 = "CaseAssessorQ269",
  CaseAssessorQ270 = "CaseAssessorQ270",
  CaseAssessorQ271 = "CaseAssessorQ271",
  CaseAssessorQ272 = "CaseAssessorQ272",
  CaseAssessorQ273 = "CaseAssessorQ273",
  CaseAssessorQ274 = "CaseAssessorQ274",
  CaseAssessorQ275 = "CaseAssessorQ275",
  CaseAssessorQ276 = "CaseAssessorQ276",
  CaseAssessorQ277 = "CaseAssessorQ277",
  CaseAssessorQ278 = "CaseAssessorQ278",
  CaseAssessorQ279 = "CaseAssessorQ279",
  CaseAssessorQ280 = "CaseAssessorQ280",
  CaseAssessorQ281 = "CaseAssessorQ281",
  CaseAssessorQ282 = "CaseAssessorQ282",
  CaseAssessorQ283 = "CaseAssessorQ283",
  CaseAssessorQ284 = "CaseAssessorQ284",
  CaseAssessorQ285 = "CaseAssessorQ285",
  CaseAssessorQ286 = "CaseAssessorQ286",
  CaseAssessorQ287 = "CaseAssessorQ287",
  CaseAssessorQ288 = "CaseAssessorQ288",
  CaseAssessorQ289 = "CaseAssessorQ289",
  CaseAssessorQ290 = "CaseAssessorQ290",
  CaseAssessorQ291 = "CaseAssessorQ291",
  CaseAssessorQ292 = "CaseAssessorQ292",
  CaseAssessorQ293 = "CaseAssessorQ293",
  CaseAssessorQ294 = "CaseAssessorQ294",
  CaseAssessorQ295 = "CaseAssessorQ295",
  CaseAssessorQ296 = "CaseAssessorQ296",
  CaseAssessorQ297 = "CaseAssessorQ297",
  CaseAssessorQ298 = "CaseAssessorQ298",
  CaseAssessorQ299 = "CaseAssessorQ299",
  CaseAssessorQ300 = "CaseAssessorQ300",
  CaseAssessorQ301 = "CaseAssessorQ301",
  CaseAssessorQ302 = "CaseAssessorQ302",
  CaseAssessorQ303 = "CaseAssessorQ303",
  CaseAssessorQ304 = "CaseAssessorQ304",
  CaseAssessorQ305 = "CaseAssessorQ305",
  CaseAssessorQ306 = "CaseAssessorQ306",
  CaseAssessorQ307 = "CaseAssessorQ307",
  CaseAssessorQ308 = "CaseAssessorQ308",
  CaseAssessorQ309 = "CaseAssessorQ309",
  CaseAssessorQ310 = "CaseAssessorQ310",
  CaseAssessorQ311 = "CaseAssessorQ311",
  CaseAssessorQ312 = "CaseAssessorQ312",
  CaseAssessorQ313 = "CaseAssessorQ313",
  CaseAssessorQ314 = "CaseAssessorQ314",
  CaseAssessorQ315 = "CaseAssessorQ315",
  CaseAssessorQ316 = "CaseAssessorQ316",
  CaseAssessorQ317 = "CaseAssessorQ317",
  CaseAssessorQ318 = "CaseAssessorQ318",
  CaseAssessorQ319 = "CaseAssessorQ319",
  CaseAssessorQ320 = "CaseAssessorQ320",
  CaseAssessorQ321 = "CaseAssessorQ321",
  CaseAssessorQ322 = "CaseAssessorQ322",
  CaseAssessorQ323 = "CaseAssessorQ323",
  CaseAssessorQ324 = "CaseAssessorQ324",
  CaseAssessorQ325 = "CaseAssessorQ325",
  CaseAssessorQ326 = "CaseAssessorQ326",
  CaseAssessorQ327 = "CaseAssessorQ327",
  CaseAssessorQ328 = "CaseAssessorQ328",
  CaseAssessorQ329 = "CaseAssessorQ329",
  CaseAssessorQ330 = "CaseAssessorQ330",
  CaseAssessorQ331 = "CaseAssessorQ331",
  CaseAssessorQ332 = "CaseAssessorQ332",
  CaseAssessorQ333 = "CaseAssessorQ333",
  CaseAssessorQ334 = "CaseAssessorQ334",
  CaseAssessorQ335 = "CaseAssessorQ335",
  CaseAssessorQ336 = "CaseAssessorQ336",
  CaseAssessorQ337 = "CaseAssessorQ337",
  CaseAssessorQ338 = "CaseAssessorQ338",
  CaseAssessorQ339 = "CaseAssessorQ339",
  CaseAssessorQ340 = "CaseAssessorQ340",
  CaseAssessorQ341 = "CaseAssessorQ341",
  CaseAssessorQ342 = "CaseAssessorQ342",
  CaseAssessorQ343 = "CaseAssessorQ343",
  CaseAssessorQ344 = "CaseAssessorQ344",
  CaseAssessorQ345 = "CaseAssessorQ345",
  CaseAssessorQ346 = "CaseAssessorQ346",
  CaseAssessorQ347 = "CaseAssessorQ347",
  CaseAssessorQ348 = "CaseAssessorQ348",
  CaseAssessorQ349 = "CaseAssessorQ349",
  CaseAssessorQ350 = "CaseAssessorQ350",
  CaseAssessorQ351 = "CaseAssessorQ351",
  CaseAssessorQ352 = "CaseAssessorQ352",
  CaseAssessorQ353 = "CaseAssessorQ353",
  CaseAssessorQ354 = "CaseAssessorQ354",
  CaseAssessorQ355 = "CaseAssessorQ355",
  CaseAssessorQ356 = "CaseAssessorQ356",
  CaseAssessorQ357 = "CaseAssessorQ357",
  CaseAssessorQ358 = "CaseAssessorQ358",
  CaseAssessorQ359 = "CaseAssessorQ359",
  CaseAssessorQ360 = "CaseAssessorQ360",
  CaseAssessorQ361 = "CaseAssessorQ361",
  CaseAssessorQ362 = "CaseAssessorQ362",
  CaseAssessorQ363 = "CaseAssessorQ363",
  CaseAssessorQ364 = "CaseAssessorQ364",
  CaseAssessorQ365 = "CaseAssessorQ365",
  CaseAssessorQ366 = "CaseAssessorQ366",
  CaseAssessorQ367 = "CaseAssessorQ367",
  CaseAssessorQ368 = "CaseAssessorQ368",
  CaseAssessorQ369 = "CaseAssessorQ369",
  CaseAssessorQ370 = "CaseAssessorQ370",
  CaseAssessorQ371 = "CaseAssessorQ371",
  CaseAssessorQ372 = "CaseAssessorQ372",
  CaseAssessorQ373 = "CaseAssessorQ373",
  CaseAssessorQ374 = "CaseAssessorQ374",
  CaseAssessorQ375 = "CaseAssessorQ375",
  CaseAssessorQ376 = "CaseAssessorQ376",
  CaseAssessorQ377 = "CaseAssessorQ377",
  CaseAssessorQ378 = "CaseAssessorQ378",
  CaseAssessorQ379 = "CaseAssessorQ379",
  CaseAssessorQ380 = "CaseAssessorQ380",
  CaseAssessorQ381 = "CaseAssessorQ381",
  CaseAssessorQ382 = "CaseAssessorQ382",
  CaseAssessorQ383 = "CaseAssessorQ383",
  CaseAssessorQ384 = "CaseAssessorQ384",
  CaseAssessorQ385 = "CaseAssessorQ385",
  CaseAssessorQ386 = "CaseAssessorQ386",
  CaseAssessorQ387 = "CaseAssessorQ387",
  CaseAssessorQ388 = "CaseAssessorQ388",
  CaseAssessorQ389 = "CaseAssessorQ389",
  CaseAssessorQ390 = "CaseAssessorQ390",
  CaseAssessorQ391 = "CaseAssessorQ391",
  CaseAssessorQ392 = "CaseAssessorQ392",
  CaseAssessorQ393 = "CaseAssessorQ393",
  CaseAssessorQ394 = "CaseAssessorQ394",
  CaseAssessorQ395 = "CaseAssessorQ395",
  CaseAssessorQ396 = "CaseAssessorQ396",
  CaseAssessorQ397 = "CaseAssessorQ397",
  CaseAssessorQ398 = "CaseAssessorQ398",
  CaseAssessorQ399 = "CaseAssessorQ399",
  CaseAssessorQ400 = "CaseAssessorQ400",
  CaseAssessorQ401 = "CaseAssessorQ401",
  CaseAssessorQ402 = "CaseAssessorQ402",
  CaseAssessorQ403 = "CaseAssessorQ403",
  CaseAssessorQ404 = "CaseAssessorQ404",
  CaseAssessorQ405 = "CaseAssessorQ405",
  CaseAssessorQ406 = "CaseAssessorQ406",
  CaseAssessorQ407 = "CaseAssessorQ407",
  CaseAssessorQ408 = "CaseAssessorQ408",
  CaseAssessorQ409 = "CaseAssessorQ409",
  CaseAssessorQ410 = "CaseAssessorQ410",
  CaseAssessorQ411 = "CaseAssessorQ411",
  CaseAssessorQ412 = "CaseAssessorQ412",
  CaseAssessorQ413 = "CaseAssessorQ413",
  CaseAssessorQ414 = "CaseAssessorQ414",
  CaseAssessorQ415 = "CaseAssessorQ415",
  CaseAssessorQ416 = "CaseAssessorQ416",
  CaseAssessorQ417 = "CaseAssessorQ417",
  CaseAssessorQ418 = "CaseAssessorQ418",
  CaseAssessorQ419 = "CaseAssessorQ419",
  CaseAssessorQ420 = "CaseAssessorQ420",
  CaseAssessorQ421 = "CaseAssessorQ421",
  CaseAssessorQ422 = "CaseAssessorQ422",
  CaseAssessorQ423 = "CaseAssessorQ423",
  CaseAssessorQ424 = "CaseAssessorQ424",
  CaseAssessorQ425 = "CaseAssessorQ425",
  CaseAssessorQ426 = "CaseAssessorQ426",
  CaseAssessorQ427 = "CaseAssessorQ427",
  CaseAssessorQ428 = "CaseAssessorQ428",
  CaseAssessorQ429 = "CaseAssessorQ429",
  CaseAssessorQ430 = "CaseAssessorQ430",
  CaseAssessorQ431 = "CaseAssessorQ431",
  CaseAssessorQ432 = "CaseAssessorQ432",
  CaseAssessorQ433 = "CaseAssessorQ433",
  CaseAssessorQ434 = "CaseAssessorQ434",
  CaseAssessorQ435 = "CaseAssessorQ435",
  CaseAssessorQ436 = "CaseAssessorQ436",
  CaseAssessorQ437 = "CaseAssessorQ437",
  CaseAssessorQ438 = "CaseAssessorQ438",
  CaseAssessorQ439 = "CaseAssessorQ439",
  CaseAssessorQ440 = "CaseAssessorQ440",
  CaseAssessorQ441 = "CaseAssessorQ441",
  CaseAssessorQ442 = "CaseAssessorQ442",
  CaseAssessorQ443 = "CaseAssessorQ443",
  CaseAssessorQ444 = "CaseAssessorQ444",
  CaseAssessorQ445 = "CaseAssessorQ445",
  CaseAssessorQ446 = "CaseAssessorQ446",
  CaseAssessorQ447 = "CaseAssessorQ447",
  CaseAssessorQ448 = "CaseAssessorQ448",
  CaseAssessorQ449 = "CaseAssessorQ449",
  CaseAssessorQ450 = "CaseAssessorQ450",
  CaseAssessorQ451 = "CaseAssessorQ451",
  CaseAssessorQ452 = "CaseAssessorQ452",
  CaseAssessorQ453 = "CaseAssessorQ453",
  CaseAssessorQ454 = "CaseAssessorQ454",
  CaseAssessorQ455 = "CaseAssessorQ455",
  CaseAssessorQ456 = "CaseAssessorQ456",
  CaseAssessorQ457 = "CaseAssessorQ457",
  CaseAssessorQ458 = "CaseAssessorQ458",
  CaseAssessorQ459 = "CaseAssessorQ459",
  CaseAssessorQ460 = "CaseAssessorQ460",
  CaseAssessorQ461 = "CaseAssessorQ461",
  CaseAssessorQ462 = "CaseAssessorQ462",
  CaseAssessorQ463 = "CaseAssessorQ463",
  CaseAssessorQ464 = "CaseAssessorQ464",
  CaseAssessorQ465 = "CaseAssessorQ465",
  CaseAssessorQ466 = "CaseAssessorQ466",
  CaseAssessorQ467 = "CaseAssessorQ467",
  CaseAssessorQ468 = "CaseAssessorQ468",
  CaseAssessorQ469 = "CaseAssessorQ469",
  CaseAssessorQ470 = "CaseAssessorQ470",
  CaseAssessorQ471 = "CaseAssessorQ471",
  CaseAssessorQ472 = "CaseAssessorQ472",
  CaseAssessorQ473 = "CaseAssessorQ473",
  CaseAssessorQ474 = "CaseAssessorQ474",
  CaseAssessorQ475 = "CaseAssessorQ475",
  CaseAssessorQ476 = "CaseAssessorQ476",
  CaseAssessorQ477 = "CaseAssessorQ477",
  CaseAssessorQ478 = "CaseAssessorQ478",
  CaseAssessorQ479 = "CaseAssessorQ479",
  CaseAssessorQ480 = "CaseAssessorQ480",
  CaseAssessorQ481 = "CaseAssessorQ481",
  CaseAssessorA129="CaseAssessorA129",
  CaseAssessorA242 = "CaseAssessorA242",
  CaseAssessorA481="CaseAssessorA481",
  CaseAssessorA169="CaseAssessorA169",
  CaseAssessorA482="CaseAssessorA482",
  CaseAssessorQ483="CaseAssessorA483",
  CaseAssessorQ487="CaseAssessorA487",
  TotalLoan="TotalLoan",
  CustomerInitialFee = "CustomerInitialFee",
  CustomerCompletionFee = "CustomerCompletionFee",
  MonthlyRate="MonthlyRate",
  DailyRate="DailyRate",
  LGDVPer="LGDVPer",
  YearlyRate="YearlyRate",
  Term="Term",
  MatterType="MatterType",
  ProviderRef="ProviderRef",
  CaseStatus="CaseStatus",
  SourceName="SourceName",
  Provider="Provider",
  ProviderCaseRef="ProviderCaseRef",
  AgreementStartDate="AgreementStartDate",
  StatusDate="StatusDate",
  RolledUpInterestForTerm="RolledUpInterestForTerm",
  BrokerAdminFee="BrokerAdminFee",
  ValuationFee="ValuationFee",
  LegalFee="LegalFee",
  Deposit="Deposit",
  OtherFee="OtherFee",
  TotalRepaymentAmount="TotalRepaymentAmount",
  LastLoanInterestRaisedAmount="LastLoanInterestRaisedAmount",
  TotalLoanInterestRaisedAmount="TotalLoanInterestRaisedAmount",
  AdditionalFee1="AdditionalFee1",
  AdditionalFee2="AdditionalFee2",
  AdditionalFee3="AdditionalFee3",
  AdditionalFee4="AdditionalFee4",
  AdditionalFee5="AdditionalFee5",
  AdditionalFee6="AdditionalFee6",
  AdditionalFee7="AdditionalFee7",
  AdditionalFee9="AdditionalFee9",
  AdditionalFee10="AdditionalFee10",
  PaymentDate="PaymentDate",
  Title="Title"
}

export default ESummaryFieldTypes;
