import React, {useContext} from "react";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import EFinanceFieldTypes from "../../models/Case/UpdateCase/FieldTypes/EFinanceFieldTypes";
import FormSelect from "../Form/FormSelect";
import FormInput from "../Form/FormInput";
import FormTextArea from "../Form/FormTextArea";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import {convertUnixTimestampToDateReturnDate} from "../../utils/commonUtils";


export default function DynamicData({id, title}: {id: string | undefined; title: string}) {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string, hasValueChanged: boolean) {
    if (!currentCase.CaseApp) {
      console.error("currentCase.CaseApp is null or undefined.");
      return;
    }

    switch (fieldName) {

      case EFinanceFieldTypes.NetClaim:
        currentCase.CaseApp.NetClaim = Number(value);
        break;
      case EFinanceFieldTypes.OriginalLoanAmount:
        currentCase.CaseApp.OriginalLoanAmount = Number(value);
        break;
      case ESummaryFieldTypes.CustomerCompletionFee:
        currentCase.CaseApp.CustomerCompletionFee = Number(value);
        break;
      case ESummaryFieldTypes.CustomerInitialFee:
        currentCase.CaseApp.CustomerInitialFee = value;
        break;
      case ESummaryFieldTypes.MonthlyRate:
          currentCase.CaseApp.InterestMonthly = value;
        break;
      case ESummaryFieldTypes.DailyRate:
          currentCase.CaseApp.InterestDaily = value;
        break;
      case ESummaryFieldTypes.LGDVPer:
          currentCase.CaseApp.LGDVPer = value;
        break;
      case ESummaryFieldTypes.YearlyRate:
          currentCase.CaseApp.InterestYearly = Number(value);
        break;
      case ESummaryFieldTypes.RolledUpInterestForTerm:
        currentCase.CaseApp.RolledUpInterestForTerm = Number(value);
        break;
      case ESummaryFieldTypes.BrokerAdminFee:
          currentCase.CaseApp.BrokerAdminFee = Number(value);
        break;
        case ESummaryFieldTypes.ValuationFee:
          currentCase.CaseApp.ValuationFee = Number(value);
        break;
        case ESummaryFieldTypes.LegalFee:
          currentCase.CaseApp.LegalFee = Number(value);
        break;
        case ESummaryFieldTypes.Deposit:
          currentCase.CaseApp.Deposit = Number(value);
        break;
        case ESummaryFieldTypes.OtherFee:
          currentCase.CaseApp.OtherFee = Number(value);
        break;
      case ESummaryFieldTypes.TotalRepaymentAmount:
        currentCase.CaseApp.TotalRepaymentAmount = value;
        break;

      case ESummaryFieldTypes.AdditionalFee1:
        currentCase.CaseApp.updateAdditionalFee(1, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee2:
        currentCase.CaseApp.updateAdditionalFee(2, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee3:
        currentCase.CaseApp.updateAdditionalFee(3, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee4:
        currentCase.CaseApp.updateAdditionalFee(4, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee5:
        currentCase.CaseApp.updateAdditionalFee(5, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee6:
        currentCase.CaseApp.updateAdditionalFee(6, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee7:
        currentCase.CaseApp.updateAdditionalFee(7, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee9:
        currentCase.CaseApp.updateAdditionalFee(9, Number(value));
        break;

      case ESummaryFieldTypes.AdditionalFee10:
        currentCase.CaseApp.updateAdditionalFee(10, Number(value));
        break;

      case ESummaryFieldTypes.LastLoanInterestRaisedAmount:
        currentCase.CaseApp.LastLoanInterestRaisedAmount = value;
        break;

        case ESummaryFieldTypes.TotalLoanInterestRaisedAmount:
        currentCase.CaseApp.TotalLoanInterestRaisedAmount = value;
        break;

      case ESummaryFieldTypes.CaseAssessorQ37:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(37, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ38:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(38, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ39:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(39, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ40:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(40, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ41:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(41, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ42:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(42, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ43:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(43, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ44:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(44, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ45:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(45, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ46:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(46, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ47:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(47, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ48:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(48, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ49:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(49, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ50:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(50, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ51:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(51, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ52:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(52, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ53:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(53, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ54:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(54, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ55:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(55, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ56:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(56, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ57:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(57, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ58:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(58, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ59:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(59, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ60:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(60, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ61:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(61, value);
        break;

        case ESummaryFieldTypes.CaseAssessorA481:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(481, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("FinancialInformation", hasValueChanged);
    setUnsavedChanges(hasValueChanged);
  }

  return (
    <div className="form-layout">
        <div className="form-column form-column-dynamic">
            {currentCaseAssessorQuestions?.DynamicData?.map((entry, index) => (
                <div key={index} className={'w'}>
                    {(() => {
                        switch(entry.FieldType) {
                            case 'Data Table':
                                return <FormSelect
                                    fieldName={entry.FieldName}
                                    label={entry.FieldName}
                                    value={entry.FieldValue}
                                    setValue={handleState}
                                    values={entry?.DataTableOptions?.map(option => option.Value) ?? []}
                                />;
                            case 'Text':
                                return <FormTextArea fieldName={entry.FieldName} label={entry.FieldName} value={entry.FieldValue} setValue={handleState} />

                                case "Date":
                                  return <FormDateInput
                                      fieldName={entry.FieldName}
                                      label={entry.FieldName}
                                      value={convertUnixTimestampToDateReturnDate(entry.FieldValue)}
                                      setValue={handleState} />;
                            // case 'Date':
                            //       return <FormDateInput fieldName={entry.FieldName} label={entry.FieldName} value={entry.FieldValue} setValue={handleState} />
                            case 'Numerical':
                                    return <FormDecimalInput fieldName={entry.FieldName} label={entry.FieldName} value={entry.FieldValue} setValue={handleState} />
                            
                            default:
                                return <span>{entry.FieldType}</span>;
                        }
                    })()}
                </div>
            ))}
        </div>
    </div>
  );
}
